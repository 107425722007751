<template>
  <div class="home">
    <BNavbar></BNavbar>
    <div class="hv-100 d-flex justify-content-center bg-home position-relative">
      <b-container>
        <b-row class="py-5">
          <b-col sm="12" md="9" class="mx-auto text-center">
            <div v-if="visible">
              <h1 class="font-weight-regular">
                <typewriter class="typewriter" :type-interval="30">
                  <span>
                    <span class="font-weight-bold no-caret">
                      Simplificando</span
                    >
                    el día a día a través de <br />
                    ecosistemas digitales
                  </span>
                </typewriter>
              </h1>
            </div>
            <transition name="fade">
              <div v-if="visibleButton">
                <router-link
                  v-on:click="scrollToTop"
                  to="/quienessomos"
                  class="btn btn-primary mt-4 btn-home"
                  >Acerca de nosotros</router-link
                >
              </div>
            </transition>
          </b-col>
          <!-- <b-col sm="12" md="8" class="mx-auto mt-5">
                        <iframe width="100%" height="400" src="https://www.youtube.com/embed/Rl_FyZDri_Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </b-col> -->
        </b-row>
      </b-container>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import BNavbar from "@/components/BNavbar.vue";
import Footer from "@/components/Footer.vue";
import Typewriter from "typewriter-vue";

export default {
  name: "Home",
  components: {
    BNavbar,
    Footer,
    Typewriter,
  },

  data() {
    return {
      scrollPosition: null,
      visible: false,
      visibleButton: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    console.log("wot");
    setTimeout(() => (this.visible = true), 1000);
    setTimeout(() => (this.visibleButton = true), 3130);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  metaInfo() {
    return {
      title: `Grupo Sable | Ecosistema Digital`,
      meta: [
        {
          name: "description",
          content:
            "Simplificando el día a día a través de Ecosistemas Digitales.",
        },
      ],
      link: [{ rel: "canonical", href: "https://sable.lat/" }],
    };
  },
};
</script>
